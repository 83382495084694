import { Edge, MarkerType, Node } from "reactflow";
import { Tables } from "../../../types/database.ts";
import { getRandomTailwindColor } from "../../../utils/color.ts";

const chapterColorMap = new Map<string, string>();

export const generateNodes = (chapters: Tables<"blueprint_chapters">[]): Node[] => {
  return chapters.map((chapter, index) => {
    let borderColor = "#000000";

    if (chapter.parent_chapter_id) {
      if (chapterColorMap.has(chapter.parent_chapter_id)) {
        borderColor = chapterColorMap.get(chapter.parent_chapter_id)!;
      } else {
        borderColor = getRandomTailwindColor(chapter.parent_chapter_id);
        chapterColorMap.set(chapter.parent_chapter_id, borderColor);
      }
    } else {
      if (!chapterColorMap.has(chapter.id)) {
        chapterColorMap.set(chapter.id, getRandomTailwindColor(chapter.id));
      }
      borderColor = chapterColorMap.get(chapter.id)!;
    }

    return {
      id: chapter.id,
      type: "custom",
      data: {
        label: chapter.chapter_name,
        chapter_type: chapter.chapter_type,
        chapter: chapter,
        borderColor: borderColor,
      },
      position: { x: 100, y: index * 100 },
      style: { border: `4px solid ${borderColor}`, borderRadius: "15px" },
    };
  });
};

export const generateEdges = (
  transitions: Tables<"blueprint_chapter_transitions">[],
  chapters: Tables<"blueprint_chapters">[],
): Edge[] => {
  if (transitions.length === 0 && chapters.length >= 2) {
    // Add a fake transition between the first two chapters
    transitions = [
      {
        blueprint_story_id: chapters[0].blueprint_story_id,
        id: "fake-transition",
        current_chapter_id: chapters[0].id,
        next_chapter_id: chapters[1].id,
        condition: "",
        created_at: null,
        updated_at: null,
      },
    ];
  }

  return transitions.map((transition) => {
    const sourceChapter = chapters.find((chapter) => chapter.id === transition.current_chapter_id);
    const targetChapter = chapters.find((chapter) => chapter.id === transition.next_chapter_id);

    let strokeColor = "#000000"; // Default black stroke

    if (
      sourceChapter &&
      targetChapter &&
      targetChapter.parent_chapter_id &&
      targetChapter.parent_chapter_id === sourceChapter.parent_chapter_id
    ) {
      strokeColor = chapterColorMap.get(sourceChapter.parent_chapter_id) || strokeColor;
    } else if (
      sourceChapter &&
      targetChapter &&
      targetChapter.parent_chapter_id === sourceChapter.id
    ) {
      strokeColor = chapterColorMap.get(sourceChapter.id) || strokeColor;
    }

    return {
      id: transition.id,
      type: "smoothstep",
      source: transition.current_chapter_id,
      target: transition.next_chapter_id,
      animated: false,
      label: transition.condition,
      style: { stroke: strokeColor },
      labelStyle: { fontSize: "12px" },
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 10,
        height: 10,
        color: strokeColor,
      },
    };
  });
};
